/* eslint-disable */
import { ProjectUsersDialogProps } from './ProjectUsersDialog.types'
import { Dialog } from '../ui/Dialog'
import ProjectUser from './ProjectUser.component'
import { FlexBox } from '../ui/FlexBox'
import styled from 'styled-components'
import { Button } from '../ui/Button'
import { useUserListQuery } from './ProjectUsersDialog.hooks'
import { UserAddField } from '../fields/UserAddField'
import { ProjectRoleIds } from '@synctech/portal-types'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const ButtonContainer = styled(FlexBox)`
  margin-top: auto;
  padding-bottom: 10px;
`

const ProjectUsersDialog = ({
  projectId,
  onDismiss,
}: ProjectUsersDialogProps) => {
  const { isWaiting } = useWaitingSpinner()

  const { userList, loading, permissions } = useUserListQuery(projectId)

  isWaiting(loading)

  return (
    <Dialog title="Users" onDismiss={onDismiss} cssClass="sync-users-modal">
      <FlexBox
        direction="column"
        gap={30}
        alignItems="stretch"
        justifyContent="flex-start"
        fullHeight
      >
        {permissions.includes('AddProjectUsers') && (
          <UserAddField projectId={projectId} permissions={permissions} />
        )}
        <FlexBox gap="5px normal" alignItems="stretch">
          {userList?.map((user) => (
            <ProjectUser
              key={user.id}
              id={user.id}
              name={user.name}
              avatar={user.avatarUrl}
              email={user.email}
              roleId={Number(user.roleId) as ProjectRoleIds}
              userId={Number(user.id)}
              projectId={projectId}
              permissions={permissions}
              isRegistered={user.isRegistered}
            />
          ))}
        </FlexBox>
        <ButtonContainer direction="row" fullWidth gap={20}>
          <Button color="light" expand="block" onClick={() => onDismiss()}>
            Close
          </Button>
        </ButtonContainer>
      </FlexBox>
    </Dialog>
  )
}

export default ProjectUsersDialog
