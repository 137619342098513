/* eslint-disable import/no-default-export */
import { useMemo } from 'react'
import { IonIcon, IonLabel, IonText, IonToggle } from '@ionic/react'
import {
  cloudDownloadOutline,
  eyeOutline,
  settingsOutline,
  shareSocialOutline,
} from 'ionicons/icons'
import styled from 'styled-components'
import { FlexBox } from '../ui/FlexBox'
import { ProjectSharedLinkControlsTypes } from './ProjectSharedLinkControls.types'
import {
  useGetCaptureReport,
  useSharedLinkDisable,
} from './ProjectSharedLinkControls.hooks'
import { Button } from '../ui/Button'
import { useNotification } from '../../contexts/notification'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectSharedLinkAccessDialog } from '../ProjectSharedLinkAccessDialog'
import { ProjectSharedLinkAccessDialogHookProps } from '../ProjectSharedLinkAccessDialog/ProjectSharedLinkAccessDialog.types'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'
import { useOrganisationContext } from '../../contexts/organisation'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const StyledFlexBox = styled(FlexBox)`
  margin-top: 52px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
const StyledLabel = styled(IonLabel)`
  && {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    display: flex;
    align-self: center;
    margin-right: 16px;
  }
`

const StyledIonText = styled(IonText)`
  font-size: 14px;
`

const StyledButton = styled(Button)`
  margin-right: 8px;
  flex-grow: 2;
`
const StyledDiv = styled.div`
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
`
const ProjectSharedLinkControls = ({
  project,
}: ProjectSharedLinkControlsTypes) => {
  const { isWaiting } = useWaitingSpinner()

  const { disableSharedLink, loading: disableLoading } = useSharedLinkDisable(
    project?.id,
  )

  isWaiting(disableLoading)

  const { notify, notifyError } = useNotification()
  const { getCaptureReport } = useGetCaptureReport()

  const { user } = useMeQuery()
  const { organisationId } = useOrganisationContext()

  const organisationSettings = useMemo(() => {
    if (!user) {
      return null
    }

    const organisation = user.organisations?.find(
      (organisation) => organisation.id === organisationId,
    )

    return organisation ? organisation.settings : null
  }, [organisationId, user?.organisations])

  const [openAccessDialog] = useDialog<ProjectSharedLinkAccessDialogHookProps>(
    ProjectSharedLinkAccessDialog,
    { project },
  )

  if (!project?.permissions.includes('EnableSharedLink')) {
    return null
  }

  const onShareClick = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/p/${project?.shared_link_uuid}`)
      .then(() => {
        notify('Sharing link has been copied to your clipboard.')
      })
      .catch(() => {
        notifyError('Failed to copy Shared Link into the clipboard.')
      })
  }

  const downloadReport = () => {
    getCaptureReport({
      variables: {
        projectId: project?.id,
      },
      onCompleted: (data) => {
        const a = window.document.createElement('a')
        a.download = `Capture Report ${project?.address}.docx`
        a.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${data?.captureReport}`
        a.click()
        a?.parentNode?.removeChild(a)
      },
      onError: () => {
        notifyError('Failed to generate Capture Report.')
      },
    })
  }

  const enableSharedLink = async (event: any) => {
    if (event.detail.checked !== project?.sharedLinkEnabled) {
      if (event.detail.checked) {
        await openAccessDialog()
      } else {
        await disableSharedLink()
      }
    }
  }

  return (
    <FlexBox fullWidth>
      <StyledFlexBox direction="row" fullWidth justifyContent="flex-start">
        <StyledLabel>Shared Link</StyledLabel>
        <StyledDiv style={{ flexGrow: 2 }}>
          <IonToggle
            key={`sharedLinkEnabled-${project?.id}-${project?.sharedLinkEnabled}`}
            slot="end"
            checked={project?.sharedLinkEnabled}
            onIonChange={enableSharedLink}
          />
        </StyledDiv>
      </StyledFlexBox>
      {!project?.sharedLinkEnabled && (
        <StyledIonText>
          Access to this project is restricted to all users specified in the
          Project Team section.
        </StyledIonText>
      )}
      {project?.sharedLinkEnabled && (
        <FlexBox direction="column" fullWidth alignItems="stretch" gap="8px 0">
          <Button onClick={openAccessDialog} color="light">
            <IonIcon slot="start" icon={settingsOutline} />
            Visibility
          </Button>
          <FlexBox direction="row" fullWidth>
            <StyledButton onClick={onShareClick}>
              <IonIcon slot="start" icon={shareSocialOutline} />
              Share
            </StyledButton>
            <Button
              color="light"
              href={`${window.location.origin}/p/${project?.shared_link_uuid}`}
            >
              <IonIcon icon={eyeOutline} />
            </Button>
          </FlexBox>
          {organisationSettings?.wordReport && (
            <Button onClick={downloadReport} color="light">
              <IonIcon slot="start" icon={cloudDownloadOutline} />
              Report
            </Button>
          )}
          <StyledIonText>
            Access to this project is available to all Project Team users and
            anyone with the link. You may restrict what is accessible to all
            non-users.
          </StyledIonText>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default ProjectSharedLinkControls
