/* eslint-disable no-unused-vars */
import {
  ProjectAssetAttributes,
  ProjectAttributes,
  ProjectStatus,
} from '@synctech/portal-types'
import { UserProfile } from '../ProfilePage/ProfilePage.types'

export type ProjectDetailsQuery = {
  id: string
}

export interface Project {
  id: string
  thumbnailUrl: string
  title?: string
  address: string
  status: ProjectStatus
  description: string
  attributes?: ProjectAttributes
  assets?: ProjectAssetsCollections
  users?: UserProfile[]
  usersPreview?: UserProfile[]
  usersCount?: number
  permissions: string[]
  sharedLinkEnabled: boolean
  // eslint-disable-next-line camelcase
  shared_link_uuid?: string
  // eslint-disable-next-line camelcase
  reference_id: string
  // eslint-disable-next-line camelcase
  created_at: string
}

export type ProjectAssetsCollections = {
  urls: [ProjectAsset]
  documents: [ProjectAsset]
  images: [ProjectAsset]
  videos: [ProjectAsset]
  custom: [ProjectAsset]
  pix4d: [ProjectAsset]
  captur3d: [ThumbnailedAsset]
  matterport: [ThumbnailedAsset]
  treedis: [ThumbnailedAsset]
  // eslint-disable-next-line camelcase
  bim_file: [ProjectAsset]
  // eslint-disable-next-line camelcase
  self_hosted_tour: [ThumbnailedAsset]
  nearmap: [ProjectAsset]
}

export type ProjectAssetsCollectionsTypes = keyof ProjectAssetsCollections

export interface ProjectProps {
  project: Project
  isShared?: boolean
}

export interface ProjectContentProps extends ProjectProps {
  project: Project
  updateQuery: any
}

export enum ProjectAssetsTypes {
  URL = 'url',
  CAPTUR3D = 'captur3d',
  MATTERPORT = 'matterport',
  TREEDIS = 'treedis',
  PIX4D = 'pix4d',
  DOCUMENT = 'document',
  IMAGE = 'image',
  VIDEO = 'video',
  CUSTOM = 'custom',
  BIM_LINK = 'bim_link',
  BIM_FILE = 'bim_file',
  SELF_HOSTED_TOUR = 'self_hosted_tour',
  NEARMAP = 'nearmap',
}

export const assetsTypesMapping = {
  url: 'urls',
  captur3d: 'captur3d',
  matterport: 'matterport',
  treedis: 'treedis',
  pix4d: 'pix4d',
  document: 'documents',
  image: 'images',
  video: 'videos',
  custom: 'custom',
  bim_file: 'bim_file',
  self_hosted_tour: 'self_hosted_tour',
  nearmap: 'nearmap',
}

export const assetsTypesToSectionMapping = {
  urls: 'url',
  captur3d: 'captur3d',
  matterport: 'matterport',
  treedis: 'treedis',
  pix4d: 'pix4d',
  documents: 'document',
  images: 'image',
  videos: 'video',
  custom: 'custom',
  bim_file: 'bim_file',
}

export type ProjectAsset = {
  id: string
  value: string
  name: string
  type: ProjectAssetsTypes
  attributes: ProjectAssetAttributes
}

export type ThumbnailedAsset = ProjectAsset & {
  previewUrl: string
}

export type MaxFileSize = {
  binary: number
  simple: number
}
