import { useQuery } from '@apollo/client'
import { getFullSizeUrl } from './ImagePreviewDialog.graphql'

export const useAssetQuery = (id: string) => {
  const { data, loading, error, updateQuery } = useQuery(getFullSizeUrl, {
    variables: { id },
  })

  return {
    asset: {
      ...(data && data.asset),
    },
    error,
    loading,
    updateQuery,
  }
}
