/* eslint-disable import/no-default-export */
import { IonCol, IonGrid, IonPage, IonRow } from '@ionic/react'
import { RouteComponentProps } from 'react-router-dom'
import clsx from 'clsx'
import { ProjectData } from '../../components/ProjectData'
import { ProjectInfo } from '../../components/ProjectInfo'
import { TopScrollMenu } from '../../components/ui/TopScrollMenu'
import { ProjectProvider } from '../../contexts/project'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ScrollMenuItems } from '../ProjectPage/ProjectPage.constants'
import { useCountableMenu } from '../ProjectPage/ProjectPage.hooks'
import { ProjectContentProps } from '../ProjectPage/ProjectPage.types'
import { BackendError } from '../../components/BackendError'
import { useProjectSharedQuery } from './ProjectSharedPage.hooks'
import { Link } from '../../components/ui/Link'
import { useAuthContext } from '../../contexts/auth'
import {
  ProjectSharedPageHeaderProps,
  ProjectSharedQuery,
} from './ProjectSharedPage.types'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'
import LogoImg from '../../assets/logo_tech.png'
import styles from './ProjectSharedPage.module.css'

const ProjectSharedPageHeader: React.FC<ProjectSharedPageHeaderProps> = ({
  project,
}) => {
  const { isLoggedIn } = useAuthContext()

  if (isLoggedIn) {
    return (
      <IonGrid className={styles.header}>
        <IonRow
          className={`${styles.headerWrapper} ion-justify-content-center  ion-align-items-center`}
        >
          <Link
            className={styles.headerLink__authorized}
            to={`/projects/${project?.id}`}
          >
            Return to project page.
          </Link>
        </IonRow>
      </IonGrid>
    )
  }

  return (
    <IonGrid className={styles.header}>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol className="ion-no-padding ion-text-right">
          <a href="https://synctech.io" target="_blank" rel="noreferrer">
            <img
              className={styles.logo}
              src={LogoImg}
              alt="SyncTech logo"
              height="70"
            />
          </a>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

const ProjectSharePageFooter: React.FC = () => (
  <div className={styles.ctaBanner}>
    Interested in what we do? Learn more about{' '}
    <a
      href="https://synctech.io"
      title="Sync Technologies"
      target="_blank"
      rel="noreferrer"
      className={styles.ctaLink}
    >
      Sync Technologies
    </a>
    .
  </div>
)

const ProjectContent: React.VFC<ProjectContentProps> = ({
  project,
  updateQuery,
}) => {
  const { isMobile } = useDisplaySize()
  const { isLoggedIn } = useAuthContext()

  const menu = useCountableMenu(ScrollMenuItems, project)

  return (
    <ProjectProvider value={{ projectId: project?.id, updateQuery }}>
      <ProjectSharedPageHeader project={project} />
      <div
        className={clsx(styles.content, {
          [styles.content__authorized]: isLoggedIn,
        })}
        id="scroll-container"
      >
        <IonGrid className={styles.contentWrapper}>
          <ProjectInfo project={project} />
          {isMobile && <TopScrollMenu menuItems={menu} />}
          <ProjectData project={project} isShared />
        </IonGrid>
        {!isLoggedIn && <ProjectSharePageFooter />}
      </div>
    </ProjectProvider>
  )
}

const ProjectSharedPage = ({
  match,
}: RouteComponentProps<ProjectSharedQuery>) => {
  const { isWaiting } = useWaitingSpinner()
  const { project, updateQuery, loading, error } = useProjectSharedQuery({
    uuid: match.params.uuid,
  })
  isWaiting(loading)

  let content = <ProjectContent project={project} updateQuery={updateQuery} />
  if (loading) {
    content = <div />
  }
  if (error) {
    let message = 'Something went wrong. Please try again later.'
    const error404 = error.graphQLErrors.find(
      (err) => err.extensions.code === '404',
    )

    if (error404) {
      message =
        'You do not have access to this page. Please contact the project owner to re-activate shared link access.'
    }

    content = <BackendError message={message} routeReturnTo="/" />
  }

  return (
    <IonPage data-testid="ProjectSharedPage">
      <div className={styles.container}>{content}</div>
    </IonPage>
  )
}

export default ProjectSharedPage
