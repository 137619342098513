/* eslint-disable import/no-default-export */
import { ProjectAssetsTypes } from '../../pages/ProjectPage/ProjectPage.types'
import { FileUploadPopoverItem } from '../ui/FileUploadPopoverItem'
import { FlexBox } from '../ui/FlexBox'
import { PopoverItem as Item } from '../ui/PopoverItem'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { useProjectContext } from '../../contexts/project'
import { ProjectDigitalTwinDialog } from '../ProjectDigitalTwinDialog'
import { ProjectAerialMapAddDialog } from '../ProjectAerialMapDialog'
import { ProjectLinkAddDialogHookProps } from '../ProjectLinkDialog/ProjectLinkDialog.types'
import { ProjectLinkAddDialog } from '../ProjectLinkDialog'
import { ProjectNoteAddDialogHookProps } from '../ProjectNoteDialog/ProjectNoteDialog.types'
import { ProjectNoteAddDialog } from '../ProjectNoteDialog'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectUsersDialogHookProps } from '../ProjectUsersDialog/ProjectUsersDialog.types'
import ProjectUsersDialog from '../ProjectUsersDialog/ProjectUsersDialog.component'
import { hasPermission } from '../../helpers/casl'
import SubmenuPopoverItem from '../ui/SubmenuPopoverItem/SubmenuPopoverItem.component'
import { usePopoverContext } from '../../contexts/popover'

const AddPopoverMenu = () => {
  const { projectId, updateQuery } = useProjectContext()
  const { closePopover } = usePopoverContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const [openUserDialog] = useDialog<ProjectUsersDialogHookProps>(
    ProjectUsersDialog,
    {
      projectId: project.id,
    },
  )

  const [addProjectDigitalTwinAssetDialogPresent] =
    useDialog<ProjectLinkAddDialogHookProps>(ProjectDigitalTwinDialog, {
      projectId,
      updateQuery,
    })

  const [addProjectAerialMapAssetDialogPresent] =
    useDialog<ProjectLinkAddDialogHookProps>(ProjectAerialMapAddDialog, {
      projectId,
      updateQuery,
    })

  const [addProjectLinkDialogPresent] =
    useDialog<ProjectLinkAddDialogHookProps>(ProjectLinkAddDialog, {
      projectId,
      updateQuery,
    })

  const [addProjectNoteDialogPresent] =
    useDialog<ProjectNoteAddDialogHookProps>(ProjectNoteAddDialog, {
      projectId,
      updateQuery,
    })

  if (!hasPermission(project, ['AddProjectAssets', 'AddProjectUsers'])) {
    return null
  }

  return (
    <FlexBox alignItems="stretch" dataTestid="ProjectAddPopoverMenu">
      {hasPermission(project, ['AddProjectUsers']) && (
        <Item onClick={openUserDialog}>User</Item>
      )}
      {hasPermission(project, ['AddProjectAssets']) && (
        <>
          <SubmenuPopoverItem title="Digital Twin">
            <Item
              onClick={() => {
                addProjectDigitalTwinAssetDialogPresent()
                closePopover()
              }}
            >
              Link
            </Item>
            <FileUploadPopoverItem type={ProjectAssetsTypes.SELF_HOSTED_TOUR}>
              File
            </FileUploadPopoverItem>
          </SubmenuPopoverItem>
          <FileUploadPopoverItem type={ProjectAssetsTypes.BIM_FILE}>
            BIM
          </FileUploadPopoverItem>
          <Item onClick={addProjectAerialMapAssetDialogPresent}>Aerial</Item>
          <FileUploadPopoverItem type={ProjectAssetsTypes.IMAGE}>
            Image
          </FileUploadPopoverItem>
          <FileUploadPopoverItem type={ProjectAssetsTypes.VIDEO}>
            Video
          </FileUploadPopoverItem>
          <FileUploadPopoverItem type={ProjectAssetsTypes.DOCUMENT}>
            Document
          </FileUploadPopoverItem>
          <Item onClick={addProjectLinkDialogPresent}>Link</Item>
          <Item onClick={addProjectNoteDialogPresent}>Note</Item>
        </>
      )}
    </FlexBox>
  )
}

export default AddPopoverMenu
