/* eslint-disable import/no-default-export */
import React from 'react'
import {
  ProjectAddDialogHookProps,
  ProjectAddDialogProps,
} from './ProjectDialog.types'
import { useAddProject } from './ProjectAddDialog.hooks'
import ProjectForm from './ProjectForm.component'
import { initialValues, validationSchema } from './ProjectForm.constants'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const ProjectAddForm = ({
  portfolioId,
  onDismiss,
  portfolioUpdateQuery,
  forceDismiss,
  setAllowedToDismiss,
  updateQuery,
}: DialogWithAlertInnerAugment & ProjectAddDialogHookProps) => {
  const { isWaiting } = useWaitingSpinner()

  const { addProject, loading } = useAddProject(
    updateQuery,
    forceDismiss,
    portfolioId,
    portfolioUpdateQuery,
  )

  isWaiting(loading)

  return (
    <ProjectForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addProject}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
      thumbnailButtonTitle="Add"
    />
  )
}

const ProjectAddDialog: React.FC<ProjectAddDialogProps> = ({
  portfolioId,
  updateQuery,
  portfolioUpdateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <ProjectAddForm
      {...props}
      updateQuery={updateQuery}
      portfolioUpdateQuery={portfolioUpdateQuery}
      portfolioId={portfolioId}
    />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Project"
      alertHeader="Cancel Adding Project?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default ProjectAddDialog
