import React, { useEffect } from 'react'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useHistory } from 'react-router-dom'
import { isLoggedInQuery } from './App.graphql'
import { useAuthContext } from './contexts/auth'
import mixpanel from './helpers/tracking'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
/* Theme variables */
import './theme/variables.css'
/* Application styles */
import './theme/style.css'
import { DialogProvider } from './contexts/dialog'
import DialogRoot from './components/ui/Dialog/DialogRoot.component'
import { BackendError } from './components/BackendError'
import { OrganisationProvider } from './contexts/organisation'
import { useMobileIntercomDisabled } from './helpers/useIntercomDisabled'
import { useWaitingSpinner } from './contexts/waitingSpinner'
import { AppNavigation } from './navigation'

/* Ionic framework configuration to enforce same look and feel across platforms */
setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
})

const SentrySetup = () => {
  const history = useHistory()
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV || 'development',
  })

  return null
}

const FallbackError = () => (
  <BackendError message="Something went wrong..." routeReturnTo="/" />
)

export const App: React.VFC = () => {
  const { setIsLoggedIn } = useAuthContext()

  const { isWaiting } = useWaitingSpinner()

  const { data, loading } = useQuery(isLoggedInQuery)
  useMobileIntercomDisabled()
  isWaiting(loading)

  useEffect(() => {
    if (data) {
      // MixPanel tracker identifier for Auth Guard
      if (data.me.id) {
        mixpanel.identify(data.me.id)
        // the difference between people.set and register described here
        // https://community.mixpanel.com/data-management-10/difference-between-register-and-people-set-43
        mixpanel.people.set({ email: data.me.email, name: data.me.name })
        mixpanel.register({
          User_ID: data.me.id,
          User_Name: data.me.name,
          User_Email: data.me.email,
        })
        Sentry.setUser(data.me)

        // @ts-ignore
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: data.me.name, // Full name
          email: data.me.email, // Email address
        })
      } else {
        mixpanel.reset()
        Sentry.configureScope((scope) => scope.setUser(null))
      }

      // Set App UI State to show Authorised | Unauthorised pages
      setIsLoggedIn(data.me.isLoggedIn)
    }
  }, [data, setIsLoggedIn])

  return (
    <IonApp>
      {!loading && (
        <DialogProvider>
          <OrganisationProvider>
            <IonReactRouter>
              <Sentry.ErrorBoundary fallback={FallbackError}>
                <SentrySetup />
                <AppNavigation />
                <DialogRoot />
              </Sentry.ErrorBoundary>
            </IonReactRouter>
          </OrganisationProvider>
        </DialogProvider>
      )}
    </IonApp>
  )
}
