/* eslint-disable import/no-default-export */
import { IonCol } from '@ionic/react'
import { SelfHostedTourStatus } from '@synctech/portal-types'
import {
  ProjectAssetsTypes,
  ThumbnailedAsset,
} from '../../pages/ProjectPage/ProjectPage.types'
import { ScrollToContainer } from '../ui/ScrollToContainer'
import { ProjectDigitalTwinAssetsProps } from './ProjectDigitalTwinAssets.types'
import Captur3DAssetCard from './Captur3dAssetsCard.component'
import SelfHostedAssetsMediaCard from './SelfHostedAssetsMediaCard.component'
import SelfHostedAssetsFileCard from './SelfHostedAssetsFileCard.component'
import TreedisAssetCard from './TreedisAssetsCard.component'
import MatterportAssetCard from './MatterportAssetsCard.component'

import MediaItemList from '../ui/Card/MediaItemList'

const ProjectDigitalTwinAssets = ({
  captur3d,
  treedis,
  matterport,
  selfHostedTour,
}: ProjectDigitalTwinAssetsProps) => {
  if (
    !captur3d?.length &&
    !treedis?.length &&
    !selfHostedTour?.length &&
    !matterport?.length
  ) {
    return null
  }

  const digitalTwinAsset = [
    ...((captur3d || []) as [ThumbnailedAsset]),
    ...((treedis || []) as [ThumbnailedAsset]),
    ...((matterport || []) as [ThumbnailedAsset]),
    ...((selfHostedTour || []) as [ThumbnailedAsset]),
  ].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <ScrollToContainer
      name="project-captur3d"
      data-testid="ProjectCaptur3dAssets"
    >
      <MediaItemList title="Digital Twin" itemCount={digitalTwinAsset?.length}>
        {digitalTwinAsset.map((asset) => (
          <IonCol
            className="cardContainer"
            key={asset.id}
            size="5"
            sizeLg="6"
            sizeXl="4"
          >
            {asset.type === ProjectAssetsTypes.CAPTUR3D && (
              <Captur3DAssetCard captur3d={asset} />
            )}
            {asset.type === ProjectAssetsTypes.TREEDIS && (
              <TreedisAssetCard treedis={asset} />
            )}
            {asset.type === ProjectAssetsTypes.MATTERPORT && (
              <MatterportAssetCard matterport={asset} />
            )}
            {asset.type === ProjectAssetsTypes.SELF_HOSTED_TOUR &&
              asset.attributes?.self_hosted_tour?.status ===
                SelfHostedTourStatus.COMPILED && (
                <SelfHostedAssetsMediaCard selfHostedTour={asset} />
              )}
            {asset.type === ProjectAssetsTypes.SELF_HOSTED_TOUR &&
              asset.attributes?.self_hosted_tour?.status !==
                SelfHostedTourStatus.COMPILED && (
                <SelfHostedAssetsFileCard selfHostedTour={asset} />
              )}
          </IonCol>
        ))}
      </MediaItemList>
    </ScrollToContainer>
  )
}

export default ProjectDigitalTwinAssets
