/* eslint-disable import/no-default-export */
import { IonCheckbox, IonContent, IonPage } from '@ionic/react'
import clsx from 'clsx'
import { Formik, ErrorMessage } from 'formik'
import { Link, RouteComponentProps } from 'react-router-dom'
import { useEffect } from 'react'
import * as yup from 'yup'
import logo from '../../assets/logo_3x.png'
import { TextInputField } from '../../components/fields/TextInputField'
import { ErrorComponent } from '../../components/ui/ErrorComponent'
import { Button } from '../../components/ui/Button'
import { FlexBox } from '../../components/ui/FlexBox'
import { useLogin } from './LoginPage.hooks'
import { LoginFormFields } from './LoginPage.types'
import { useAuthContext } from '../../contexts/auth'
import styles from './LoginPage.module.css'

const validationSchema = yup.object({
  password: yup.string().required('Password is required').nullable(),
  email: yup
    .string()
    .email('Must be email')
    .required('Email is required')
    .nullable(),
})

const initialValues: LoginFormFields = {
  email: null,
  password: null,
  error: null,
}

const LoginPage = ({ location }: RouteComponentProps) => {
  const { setRedirectUrl } = useAuthContext()
  useEffect(() => {
    // set redirect url in the auth context if referrer is provided
    // @ts-ignore
    if (location.state?.referrer) {
      // @ts-ignore
      setRedirectUrl(location.state.referrer.pathname)
    }
  })

  const { handleLogin } = useLogin()

  return (
    <IonPage data-testid="LoginPage">
      <IonContent className="ion-padding-horizontal">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ submitForm }) => (
            <div className={styles.container}>
              <FlexBox>
                <img
                  className={clsx(styles.logo, 'ion-margin-bottom')}
                  src={logo}
                  alt="logo"
                />
                <FlexBox fullWidth alignItems="stretch">
                  <p className="ion-margin-bottom ion-text-center">
                    Log in to your account
                  </p>
                  <TextInputField
                    className="ion-margin-vertical"
                    name="email"
                    placeholder="Email"
                    autocomplete="email"
                  />
                  <TextInputField
                    className="ion-margin-bottom"
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                  <FlexBox
                    className="ion-margin-bottom"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <FlexBox direction="row">
                      <IonCheckbox checked />
                      <span className="ion-margin-start">Remember me</span>
                    </FlexBox>
                    <div>
                      <Link className={styles.link} to="/forgot">
                        Forgot password?
                      </Link>
                    </div>
                  </FlexBox>

                  <Button className="ion-margin-bottom" onClick={submitForm}>
                    Login
                  </Button>
                  <ErrorMessage name="error" component={ErrorComponent} />
                </FlexBox>
              </FlexBox>
            </div>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  )
}

export default LoginPage
