/* eslint-disable import/no-default-export */
import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../ui/Button'
import { FlexBox } from '../ui/FlexBox'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import BookScanButton from '../ui/BookScanButton/BookScanButton.component'
import { PortfolioProps } from '../../pages/PortfolioPage/PortfolioPage.types'
import { usePortfolioContext } from '../../contexts/portfolio'
import { PortfolioEditDialogHookProps } from '../PortfolioDialog/PortfolioDialog.types'
import { PortfolioEditDialog } from '../PortfolioDialog'
import { useProjectListContext } from '../../contexts/projectList'
import { ProjectAddDialogHookProps } from '../ProjectDialog/ProjectDialog.types'
import ProjectAddDialog from '../ProjectDialog/ProjectAddDialog.component'
import { effectiveOrganisationPermissions } from '../../helpers/casl'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'
import { useOrganisationContext } from '../../contexts/organisation'
import { ProjectUsers } from '../ProjectUsers'
import { PortfolioUsersDialogHookProps } from '../PortfolioUsersDialog/PortfolioUsersDialog.types'
import { PortfolioUsersDialog } from '../PortfolioUsersDialog'
import { Popover } from '../ui/Popover'
import { PopoverItemWithAlert } from '../ui/PopoverItemWithAlert'
import { usePopoverContext } from '../../contexts/popover'
import { usePortfolioDelete } from './PortfolioDesktopControls.hooks'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const StyledAside = styled.div`
  margin-top: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
  width: 198px;
  margin-left: auto;
  padding-left: 10px;
`

const EditButton = styled(Button)`
  && {
    margin: 0 8px 0 0;
    width: 100%;
    ::part(native) {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`

const AddButton = styled(Button)`
  && {
    ::part(native) {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`

const morePopoverCss = css`
  && {
    .popover-content {
      width: 227px;
      transform: translateX(-89px) translateY(3px);
    }

    @media (max-width: 1440px) {
      .popover-content {
        transform: translateX(-50px) translateY(3px);
      }
    }
  }
`

const StyledFlexBox = styled(FlexBox)`
  margin-top: 10px;
`

const TriggerMoreButton = () => {
  const { openPopover } = usePopoverContext()
  // TODO figure out why stylings by styled-components break popover positioning
  const style = { margin: '0' }

  return (
    <Button style={style} onClick={openPopover} color="light">
      ...
    </Button>
  )
}

const PortfolioDesktopControls: React.FC<PortfolioProps> = ({ portfolio }) => {
  const { user } = useMeQuery()
  const { organisationId } = useOrganisationContext()

  const { isWaiting } = useWaitingSpinner()

  const { portfolioId, updateQuery } = usePortfolioContext()
  const [editPortfolioDialogPresent] = useDialog<PortfolioEditDialogHookProps>(
    PortfolioEditDialog,
    {
      portfolioId,
      updateQuery,
      portfolio,
    },
  )

  const { updateQuery: projectUpdateQuery } = useProjectListContext()

  const [addProjectDialogPresent] = useDialog<ProjectAddDialogHookProps>(
    ProjectAddDialog,
    {
      portfolioId,
      updateQuery: projectUpdateQuery,
      portfolioUpdateQuery: updateQuery,
    },
  )

  const [openUserDialog] = useDialog<PortfolioUsersDialogHookProps>(
    PortfolioUsersDialog,
    {
      portfolioId: portfolio.id,
    },
  )

  const { deletePortfolio, loading: portfolioDeleteLoading } =
    usePortfolioDelete()

  isWaiting(portfolioDeleteLoading)

  const permissions = effectiveOrganisationPermissions(user, organisationId)

  return (
    <StyledAside data-testid="PortfolioDesktopControls">
      <FlexBox alignItems="stretch">
        <BookScanButton />
        {permissions.includes('CreateProject') && (
          <AddButton onClick={addProjectDialogPresent}>+ Add Project</AddButton>
        )}
        <StyledFlexBox direction="row" fullWidth justifyContent="flex-end">
          {portfolio.permissions.includes('EditPortfolioDetails') && (
            <EditButton color="light" onClick={editPortfolioDialogPresent}>
              Edit
            </EditButton>
          )}
          <Popover css={morePopoverCss} trigger={TriggerMoreButton}>
            <FlexBox alignItems="stretch">
              {portfolio.permissions.includes('DeletePortfolio') && (
                <PopoverItemWithAlert
                  header="Delete"
                  message=" Are you sure you want to Delete this portfolio?"
                  confirmButtonText="Delete"
                  onClick={deletePortfolio}
                  globalSpinner
                >
                  Delete{' '}
                </PopoverItemWithAlert>
              )}
            </FlexBox>
          </Popover>
        </StyledFlexBox>
        {portfolio?.permissions.includes('ViewPortfolioUsers') &&
          portfolio?.usersPreview &&
          portfolio.usersCount && (
            <ProjectUsers
              users={portfolio.usersPreview}
              usersCount={portfolio.usersCount}
              onClick={openUserDialog}
              title="Portfolio Team"
            />
          )}
      </FlexBox>
    </StyledAside>
  )
}

export default PortfolioDesktopControls
