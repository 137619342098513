/* eslint-disable import/no-default-export */
import React from 'react'
import { IonCol, IonRow } from '@ionic/react'
import styled from 'styled-components'

import { ProjectProps } from '../../pages/ProjectPage/ProjectPage.types'
import { ProjectDescription } from '../ProjectDescription'
import { ProjectDocuments } from '../ProjectDocuments'
import { ProjectImages } from '../ProjectImages'
import { ProjectLinks } from '../ProjectLinks'
import { ProjectVideos } from '../ProjectVideos'
import { ProjectNotes } from '../ProjectNotes'
import { ProjectDesktopControls } from '../ProjectDesktopControls'
import { AsideScrollMenu } from '../ui/AsideScrollMenu'
import { ScrollMenuItems } from '../../pages/ProjectPage/ProjectPage.constants'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ProjectUsers } from '../ProjectUsers'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectUsersDialogHookProps } from '../ProjectUsersDialog/ProjectUsersDialog.types'
import ProjectUsersDialog from '../ProjectUsersDialog/ProjectUsersDialog.component'
import { ProjectPix4dAssets } from '../ProjectAerialMapAssets'
import { ProjectDigitalTwinAssets } from '../ProjectDigitalTwinAsset'
import { hasPermission } from '../../helpers/casl'
import { useCountableMenu } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ProjectBimAssets } from '../ProjectBimAssets'
import styles from './ProjectData.module.css'
import LogoImg from '../../assets/logo_tech.png'

const StyledIonRow = styled(IonRow)`
  max-width: 1440px;
  margin: 0 auto;
`

interface StyledCenterColProps {
  isShared: boolean
}

const StyledCenterCol = styled(IonCol)<StyledCenterColProps>`
  max-width: ${(props) => (props.isShared ? 'none' : '798px')};
  margin: 0 auto;
`

const ProjectData: React.FC<ProjectProps> = ({ project, isShared = false }) => {
  const { isMobile } = useDisplaySize()

  const [openUserDialog] = useDialog<ProjectUsersDialogHookProps>(
    ProjectUsersDialog,
    {
      projectId: project.id,
    },
  )

  const menu = useCountableMenu(ScrollMenuItems, project)

  return (
    <StyledIonRow className="ion-justify-content-center">
      {!isMobile && (
        <IonCol className={styles.asideMenu} size="auto">
          <AsideScrollMenu menuItems={menu} />
          {isShared && (
            <div className={styles.asideLogo}>
              Powered by{' '}
              <img
                className={styles.logoImg}
                src={LogoImg}
                alt="SyncTech logo"
                height="60"
              />
            </div>
          )}
        </IonCol>
      )}
      <StyledCenterCol isShared={isShared}>
        {!isShared &&
          isMobile &&
          hasPermission(project, ['ViewProjectUsers']) &&
          project?.usersPreview &&
          project?.usersCount && (
            <ProjectUsers
              users={project.usersPreview}
              usersCount={project.usersCount}
              onClick={openUserDialog}
            />
          )}
        {!isShared && <ProjectDescription description={project?.description} />}

        {isShared && project?.description && (
          <ProjectDescription description={project?.description} />
        )}
        <ProjectDigitalTwinAssets
          captur3d={project?.assets?.captur3d}
          treedis={project?.assets?.treedis}
          selfHostedTour={project?.assets?.self_hosted_tour}
          matterport={project?.assets?.matterport}
        />
        <ProjectBimAssets bimFiles={project?.assets?.bim_file} />
        <ProjectPix4dAssets
          pix4d={project?.assets?.pix4d}
          nearmap={project?.assets?.nearmap}
        />
        <ProjectImages images={project?.assets?.images} />
        <ProjectVideos videos={project?.assets?.videos} />
        <ProjectDocuments documents={project?.assets?.documents} />
        <ProjectLinks links={project?.assets?.urls} />
        <ProjectNotes notes={project?.assets?.custom} />
      </StyledCenterCol>
      {!isShared && !isMobile && (
        <IonCol size="auto">
          {project && <ProjectDesktopControls project={project} />}
        </IonCol>
      )}
    </StyledIonRow>
  )
}

export default ProjectData
