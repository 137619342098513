/* eslint-disable import/no-default-export */
import {
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
} from '@ionic/react'
import { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { ProjectMobileControls } from '../../components/ProjectMobileControls'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { ProjectAddPopoverMenu } from '../../components/ProjectAddPopoverMenu'
import { BackendError } from '../../components/BackendError'
import {
  PortfolioContentProps,
  PortfolioDetailsQuery,
} from './PortfolioPage.types'
import { usePortfolioQuery } from './PortfolioPage.hooks'
import { PortfolioInfo } from '../../components/PortfolioInfo'
import { PortfolioData } from '../../components/PortfolioData'
import { PortfolioProvider } from '../../contexts/portfolio'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    margin: auto;
    max-width: var(--page-max-size);
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const PortfolioContent: React.VFC<PortfolioContentProps> = ({
  portfolio,
  loading,
  updateQuery,
}) => {
  const { isMobile } = useDisplaySize()

  return (
    <PortfolioProvider value={{ portfolioId: portfolio?.id, updateQuery }}>
      <StyledContent id="scroll-container">
        <StyledIonGrid style={{ marginBottom: isMobile ? '112px' : 0 }}>
          <PortfolioInfo portfolio={portfolio} loading={loading} />
          <PortfolioData portfolio={portfolio} loading={loading} />
        </StyledIonGrid>
        {isMobile && (
          <ProjectMobileControls menuItems={ProjectAddPopoverMenu} />
        )}
      </StyledContent>
    </PortfolioProvider>
  )
}

const PortfolioPage = ({
  match,
}: RouteComponentProps<PortfolioDetailsQuery>) => {
  const { portfolio, updateQuery, loading, error, fetchMore } =
    usePortfolioQuery({
      id: match.params.id,
    })

  const [hasMore, setHasMore] = useState(true)

  const InfiniteScroll = () => {
    const onInfinite = async () => {
      let data = null
      if (portfolio && portfolio.projects?.length) {
        ;({ data } = await fetchMore({
          variables: { id: match.params.id, offset: portfolio.projects.length },
        }))
      }

      if (data?.portfolio.projects.length === 0) {
        setHasMore(false)
      }
    }

    return (
      <IonInfiniteScroll
        onIonInfinite={onInfinite}
        threshold="120px"
        disabled={!hasMore}
      >
        <IonInfiniteScrollContent loadingSpinner="lines-small" />
      </IonInfiniteScroll>
    )
  }

  let content = (
    <IonContent>
      {!loading && (
        <PortfolioContent
          portfolio={portfolio}
          updateQuery={updateQuery}
          loading={loading}
        />
      )}
      <InfiniteScroll />
    </IonContent>
  )

  if (error) {
    let message = 'Something went wrong. Please try again later.'
    const error404 = error.graphQLErrors.find(
      (err) => err.extensions.code === '404',
    )

    if (error404) {
      message =
        'This portfolio does not exist or You don’t have permission to see this portfolio.'
    }

    content = <BackendError message={message} routeReturnTo="/" />
  }

  return (
    <IonPage data-testid="PortfolioPage">
      <StyledPageContainer>
        <Header />
        {content}
      </StyledPageContainer>
    </IonPage>
  )
}

export default PortfolioPage
