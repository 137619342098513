/* eslint-disable import/no-default-export */
import React from 'react'
import {
  PortfolioAddDialogHookProps,
  PortfolioAddDialogProps,
} from './PortfolioDialog.types'
import { useAddPortfolio } from './PortfolioAddDialog.hooks'
import PortfolioForm from './PortfolioForm.component'
import { initialValues, validationSchema } from './PortfolioForm.constants'
import { DialogWithAlertInnerAugment } from '../ui/DialogWithAlert/DialogWithAlert.types'
import { DialogWithAlert } from '../ui/DialogWithAlert'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const PortfolioAddForm = ({
  onDismiss,
  forceDismiss,
  setAllowedToDismiss,
  updateQuery,
}: DialogWithAlertInnerAugment & PortfolioAddDialogHookProps) => {
  const { isWaiting } = useWaitingSpinner()

  const { addPortfolio, loading } = useAddPortfolio(updateQuery, forceDismiss)

  isWaiting(loading)
  return (
    <PortfolioForm
      onDismiss={onDismiss}
      forceDismiss={forceDismiss}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addPortfolio}
      allowedToDismiss={false}
      setAllowedToDismiss={setAllowedToDismiss}
    />
  )
}

const PortfolioAddDialog: React.FC<PortfolioAddDialogProps> = ({
  updateQuery,
  onDismiss,
}) => {
  const FormWrapper = (props: any) => (
    <PortfolioAddForm {...props} updateQuery={updateQuery} />
  )

  return (
    <DialogWithAlert
      dialogTitle="Add Portfolio"
      alertHeader="Cancel Adding Portfolio?"
      alertMessage="Fields contain unsaved changes. Are you sure you want to cancel and leave this form?"
      alertConfirmLabel="Cancel & Leave"
      alertCancelLabel="Stay"
      onDismiss={onDismiss}
      content={FormWrapper}
    />
  )
}

export default PortfolioAddDialog
