/* eslint-disable no-unused-vars */
import { createContext, useContext, useState } from 'react'
import WaitingSpinner from '../components/ui/WaitingSpinner/WaitingSpinner'

interface WaitingSpinnerContext {
  isWaiting: (waiting: boolean) => void
}

const Context = createContext<WaitingSpinnerContext>({
  isWaiting: () => {},
})

export const useWaitingSpinner = () =>
  useContext<WaitingSpinnerContext>(Context)

export const WaitingSpinnerProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isVisible, setIsVisible] = useState<any>(true)
  const isWaiting = (visible: boolean) => setIsVisible(visible)

  return (
    <Context.Provider value={{ isWaiting }}>
      {children}
      {isVisible && <WaitingSpinner />}
    </Context.Provider>
  )
}
