import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'
import { createRoot } from 'react-dom/client'
import possibleTypes from './possibleTypes.json'
import { App } from './App'
import { AuthProvider } from './contexts/auth'
import { NotificationProvider } from './contexts/notification'
import { customFetch } from './helpers/cutomFetch'
import { hotjar } from './helpers/hotjar'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { StorageProvider } from './contexts/storage'
import { WaitingSpinnerProvider } from './contexts/waitingSpinner'

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_HOST,
  credentials: 'include',
  fetch: customFetch as any,
}) as unknown as ApolloLink

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes,
    // addTypename: false, // https://github.com/apollographql/apollo-client/issues/1564
    typePolicies: {
      Portfolio: {
        fields: {
          projects: offsetLimitPagination(),
        },
      },
      Query: {
        fields: {
          project: {
            merge: true,
          },
          projects: offsetLimitPagination([
            'organisationId',
            'searchQuery',
            'orderField',
            'orderDirection',
          ]),
          portfolio: {
            merge: true,
          },
        },
      },
    },
  }),
  link,
})

const container = document.getElementById('root')

const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <NotificationProvider>
          <StorageProvider>
            <WaitingSpinnerProvider>
              <App />
            </WaitingSpinnerProvider>
          </StorageProvider>
        </NotificationProvider>
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
