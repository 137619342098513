/* eslint-disable import/no-default-export */
import { IonAvatar, IonText } from '@ionic/react'
import styled from 'styled-components'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { FlexBox } from '../ui/FlexBox'
import { UserProfileProps } from './UserProfile.types'
import { Title } from '../ui/Title'
import defaultAvatar from '../../assets/default-avatar.svg'
import { Anchor } from '../ui/Link'

const FieldLabelText = styled(IonText)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    border-bottom: 3px solid var(--sync-red);
    flex: 0 1 fit-content;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`

const StyledFlexBox = styled(FlexBox)`
  margin-top: 26px;
`

const Avatar = styled(IonAvatar)`
  --border-radius: 100px;
  min-width: 200px;
  min-height: 200px;
  object-fit: cover;
  background-color: white;
`

const StyledAnchor = styled(Anchor)`
  font-weight: inherit;
  font-size: inherit;
`
const UserProfileView = ({ user }: UserProfileProps) => {
  const { isMobile } = useDisplaySize()

  return (
    <>
      <Title>Profile Details</Title>
      <StyledFlexBox
        alignItems={isMobile ? 'center' : 'flex-start'}
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        gap={70}
        dataTestid="UserProfileView"
      >
        <Avatar>
          <img
            src={user?.avatarUrl || defaultAvatar}
            alt={user?.name}
            title={user?.name}
          />
        </Avatar>
        <FlexBox alignItems="flex-start" fullWidth gap={20}>
          <div>
            <FieldLabelText>Full Name</FieldLabelText>
            <p>{user?.name}</p>
          </div>
          <div>
            <FieldLabelText>Email</FieldLabelText>
            <p>
              <StyledAnchor href={`mailto:${user?.email}`}>
                {user?.email}
              </StyledAnchor>
            </p>
          </div>
          {user?.phone && (
            <div>
              <FieldLabelText>Contact phone</FieldLabelText>
              <p>
                <StyledAnchor href={`tel:${user?.phone}`}>
                  {formatPhoneNumberIntl(user?.phone)}
                </StyledAnchor>
              </p>
            </div>
          )}
        </FlexBox>
      </StyledFlexBox>
    </>
  )
}

export default UserProfileView
