/* eslint-disable import/no-default-export */
import React from 'react'
import styled, { css } from 'styled-components'
import { ProjectAddPopoverMenu } from '../ProjectAddPopoverMenu'
import { Button } from '../ui/Button'
import { FlexBox } from '../ui/FlexBox'
import { Popover } from '../ui/Popover'
import { usePopoverContext } from '../../contexts/popover'
import { useDialog } from '../ui/Dialog/Dialog.hooks'
import { ProjectProps } from '../../pages/ProjectPage/ProjectPage.types'
import { useProjectContext } from '../../contexts/project'
import { ProjectEditDialog } from '../ProjectDialog'
import { ProjectEditDialogHookProps } from '../ProjectDialog/ProjectDialog.types'
import { PopoverItemWithAlert } from '../ui/PopoverItemWithAlert'
import { useProjectDelete } from './ProjectDesktopControls.hooks'
import { ProjectUsers } from '../ProjectUsers'
import { ProjectUsersDialogHookProps } from '../ProjectUsersDialog/ProjectUsersDialog.types'
import ProjectUsersDialog from '../ProjectUsersDialog/ProjectUsersDialog.component'
import BookScanButton from '../ui/BookScanButton/BookScanButton.component'
import { ProjectSharedLinkControls } from '../ProjectSharedLinkControls'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const StyledAside = styled.div`
  margin-top: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 68px;
  width: 198px;
  margin-left: auto;
  padding-left: 10px;
`

const addPopoverCss = css`
  && {
    .popover-content {
      width: 227px;
      transform: translateX(-19px) translateY(3px);
    }
  }
`

const EditButton = styled(Button)`
  && {
    margin: 0 8px 0 0;
    &::part(native) {
      padding-right: 14px;
      padding-left: 14px;
    }
  }
`

const morePopoverCss = css`
  && {
    .popover-content {
      width: 227px;
      transform: translateX(-89px) translateY(3px);
    }

    @media (max-width: 1440px) {
      .popover-content {
        transform: translateX(-50px) translateY(3px);
      }
    }
  }
`

const TriggerAddButton = () => {
  const { openPopover } = usePopoverContext()
  // TODO figure out why stylings by styled-components break popover positioning
  const style = { margin: '0 0 8px' }

  return (
    <Button style={style} onClick={openPopover}>
      + Add
    </Button>
  )
}

const TriggerMoreButton = () => {
  const { openPopover } = usePopoverContext()
  // TODO figure out why stylings by styled-components break popover positioning
  const style = { margin: '0' }

  return (
    <Button style={style} onClick={openPopover} color="light">
      ...
    </Button>
  )
}

const ProjectDesktopControls: React.FC<ProjectProps> = ({ project }) => {
  const { projectId, updateQuery } = useProjectContext()
  const { isWaiting } = useWaitingSpinner()

  const [editProjectDialogPresent] = useDialog<ProjectEditDialogHookProps>(
    ProjectEditDialog,
    {
      projectId,
      updateQuery,
      project,
    },
  )

  // eslint-disable-next-line operator-linebreak
  const [openUserDialog] = useDialog<ProjectUsersDialogHookProps>(
    ProjectUsersDialog,
    {
      projectId: project.id,
    },
  )

  const { deleteProject, loading: projectDeleteLoading } = useProjectDelete()

  isWaiting(projectDeleteLoading)

  const popoverContent = ProjectAddPopoverMenu()

  return (
    <StyledAside data-testid="ProjectDesktopControls">
      <FlexBox alignItems="stretch">
        <BookScanButton />
        {popoverContent && (
          <Popover css={addPopoverCss} trigger={TriggerAddButton}>
            {popoverContent}
          </Popover>
        )}
        <FlexBox direction="row" fullWidth justifyContent="flex-end">
          {project.permissions.includes('EditProjectDetails') && (
            <EditButton color="light" onClick={editProjectDialogPresent}>
              Edit Project
            </EditButton>
          )}
          {project.permissions.includes('DeleteProject') && (
            <Popover css={morePopoverCss} trigger={TriggerMoreButton}>
              <FlexBox alignItems="stretch">
                <PopoverItemWithAlert
                  header="Delete"
                  message=" Are you sure you want to Delete this project?"
                  confirmButtonText="Delete"
                  onClick={deleteProject}
                  globalSpinner
                >
                  Delete{' '}
                </PopoverItemWithAlert>
              </FlexBox>
            </Popover>
          )}
        </FlexBox>
        <ProjectSharedLinkControls project={project} />

        {project?.permissions.includes('ViewProjectUsers') &&
          project?.usersPreview &&
          project.usersCount && (
            <ProjectUsers
              users={project.usersPreview}
              usersCount={project.usersCount}
              onClick={openUserDialog}
            />
          )}
      </FlexBox>
    </StyledAside>
  )
}

export default ProjectDesktopControls
