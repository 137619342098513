/* eslint-disable import/no-default-export */
import { IonContent, IonPage } from '@ionic/react'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { TextInputField } from '../../components/fields/TextInputField'
import { ErrorComponent } from '../../components/ui/ErrorComponent'
import { Button } from '../../components/ui/Button'
import { FlexBox } from '../../components/ui/FlexBox'
import { useForgot } from './ForgotPage.hooks'
import { ForgotPageContainer } from './ForgotPage.styled'
import { ForgotFormFields } from './ForgotPage.types'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Must be email')
    .required('Email is required')
    .nullable(),
})

const initialValues: ForgotFormFields = {
  email: '',
  error: null,
}

const ForgotPage: React.FC = () => {
  const { handleForgot, loading, isEmailSent } = useForgot()

  return (
    <IonPage data-testid="ForgotPage">
      <IonContent className="ion-padding-horizontal">
        <ForgotPageContainer>
          {!isEmailSent ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForgot}
            >
              {({ submitForm }) => (
                <FlexBox fullWidth alignItems="stretch">
                  <h2 className="ion-text-center">Forgot Password</h2>
                  <p className="ion-text-justify">
                    Enter your email address below and we will send you a link
                    to reset your password.
                  </p>
                  <TextInputField
                    className="ion-margin-vertical"
                    name="email"
                    placeholder="Email"
                    autocomplete="email"
                  />

                  <Button disabled={loading} onClick={submitForm}>
                    Reset Password
                  </Button>
                  <Link className="ion-text-center ion-margin-top" to="/login">
                    Back
                  </Link>
                  <FlexBox>
                    <ErrorMessage name="error" component={ErrorComponent} />
                  </FlexBox>
                </FlexBox>
              )}
            </Formik>
          ) : (
            <FlexBox alignItems="stretch">
              <h2 className="ion-text-center">Check your email</h2>
              <p className="ion-text-justify">
                If the email you provided has a registered account, you will
                receive an email with the link to reset your password
              </p>
              <p className="ion-text-justify ion-padding-bottom">
                Click on the link in the email to continue resetting your
                password.
              </p>
              <Button routerDirection="back" routerLink="/login">
                Go back to login
              </Button>
            </FlexBox>
          )}
        </ForgotPageContainer>
      </IonContent>
    </IonPage>
  )
}

export default ForgotPage
