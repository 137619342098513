import { gql } from '@apollo/client'

export const getFullSizeUrl = gql`
  query getAsset($id: ID) {
    asset(id: $id) {
      id
      name
      ... on ImageAsset {
        fullSizeUrl
      }
    }
  }
`
