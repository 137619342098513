/* eslint-disable import/no-default-export */
import { Field, FormikProvider, useFormik } from 'formik'
import { IonCheckbox, IonItem } from '@ionic/react'
import React, { useEffect, useMemo } from 'react'
import { SelfHostedTourStatus } from '@synctech/portal-types'
import {
  ProjectSharedLinkAccessFormTypes,
  ProjectSharedLinkFormValues,
} from './ProjectSharedLinkAccessForm.types'
import ProjectSharedLinkSection from './ProjectSharedLinkSection.component'
import { FlexBox } from '../ui/FlexBox'
import { Button } from '../ui/Button'
import {
  Project,
  ProjectAsset,
  ProjectAssetsTypes,
} from '../../pages/ProjectPage/ProjectPage.types'
import { useOrganisationContext } from '../../contexts/organisation'
import { useMeQuery } from '../ui/UserInfo/UserInfo.hooks'
import { useUpdateProjectPublicItems } from './ProjectSharedLinkAccessDialog.hooks'
import { validationSchema } from './ProjectSharedLinkAccessForm.constants'
import styles from './ProjectSharedLinkAccessForm.module.css'
import { useWaitingSpinner } from '../../contexts/waitingSpinner'

const defaultAssetsFilterFunction =
  (filters: string[] | undefined) => (asset: ProjectAsset) =>
    filters === undefined || filters === null || filters.length === 0
      ? true
      : filters.every((f) => {
          const pattern = f.slice(1, f.lastIndexOf('/'))
          const flags = f.slice(f.lastIndexOf('/') + 1)
          return !new RegExp(pattern, flags).test(asset.name)
        })

function getDefaultAssetIds(
  project: Project,
  filterFunction: (asset: ProjectAsset) => boolean,
) {
  return Object.values(project.assets || {})
    .flat()
    .filter(filterFunction)
    .map((asset: ProjectAsset) => asset.id)
}

const ProjectSharedLinkAccessForm: React.FC<
  ProjectSharedLinkAccessFormTypes
> = ({ onDismiss, forceDismiss, setAllowedToDismiss, project }) => {
  const { organisationId } = useOrganisationContext()
  const { user } = useMeQuery()

  const organisationSettings = useMemo(() => {
    if (!user) {
      return null
    }

    const organisation = user.organisations?.find(
      (organisation) => organisation.id === organisationId,
    )

    return organisation ? organisation.settings : null
  }, [organisationId, user?.organisations])

  const { isWaiting } = useWaitingSpinner()

  const { updatePublicItems, loading } = useUpdateProjectPublicItems(
    project.id,
    forceDismiss,
  )

  isWaiting(loading)
  const onSaveClick = async (values: ProjectSharedLinkFormValues) => {
    await updatePublicItems(values.isVisibleDescription, values.visibleAssets)
  }

  const initialValues = {
    isVisibleDescription:
      project.attributes?.projectPublicItems?.description === undefined
        ? true
        : project.attributes?.projectPublicItems?.description,
    visibleAssets:
      project.attributes?.projectPublicItems?.assets === undefined
        ? getDefaultAssetIds(
            project,
            defaultAssetsFilterFunction(
              organisationSettings?.sharedLinkAssetsDefaultsFilter,
            ),
          )
        : project.attributes?.projectPublicItems?.assets,
  }

  const formik = useFormik({
    initialValues: project.sharedLinkEnabled
      ? initialValues
      : { isVisibleDescription: false, visibleAssets: [] },
    validationSchema,
    onSubmit: onSaveClick,
  })

  useEffect(() => {
    if (!project.sharedLinkEnabled) {
      formik.setValues(initialValues)
    }
  }, [project.sharedLinkEnabled])

  useEffect(() => {
    setAllowedToDismiss(!formik.dirty)
  }, [formik.dirty])

  const digitalTwinAsset = [
    ...((project?.assets?.captur3d || []) as [ProjectAsset]),
    ...((project?.assets?.self_hosted_tour || []) as [ProjectAsset]),
  ]
    .filter(
      (asset) =>
        asset.type === ProjectAssetsTypes.CAPTUR3D ||
        (asset.type === ProjectAssetsTypes.SELF_HOSTED_TOUR &&
          asset.attributes?.self_hosted_tour?.status ===
            SelfHostedTourStatus.COMPILED),
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const bimAsset = [
    ...((project?.assets?.bim_file || []) as [ProjectAsset]),
  ].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <FormikProvider value={formik}>
      <Field name="isVisibleDescription">
        {({
          field, // { name, value, onChange, onBlur }
        }: any) => (
          <IonItem button={false}>
            <IonCheckbox
              slot="start"
              name="isVisibleDescription"
              labelPlacement="end"
              checked={field.value}
              onIonChange={(e) => {
                formik.setFieldValue('isVisibleDescription', e.detail.checked)
              }}
            >
              Description
            </IonCheckbox>
          </IonItem>
        )}
      </Field>
      <Field name="visibleAssets">
        {({
          field, // { name, value, onChange, onBlur }
        }: any) => (
          <>
            <ProjectSharedLinkSection
              sectionName="Digital Twin"
              assets={digitalTwinAsset}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="BIM"
              assets={bimAsset}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Aerial"
              assets={project?.assets?.pix4d}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Images"
              assets={project?.assets?.images}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Videos"
              assets={project?.assets?.videos}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Documents"
              assets={project?.assets?.documents}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Links"
              assets={project?.assets?.urls}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
            <ProjectSharedLinkSection
              sectionName="Notes"
              assets={project?.assets?.custom}
              visibleAssets={field.value}
              setVisibleAssets={(value) =>
                formik.setFieldValue('visibleAssets', value)
              }
            />
          </>
        )}
      </Field>
      <FlexBox
        className={styles.buttonContainer}
        direction="row"
        fullWidth
        gap={20}
      >
        <Button
          className={styles.dialogButton}
          color="light"
          expand="block"
          onClick={onDismiss}
        >
          Cancel
        </Button>
        <Button
          className={styles.dialogButton}
          expand="block"
          onClick={formik.submitForm}
        >
          Save
        </Button>
      </FlexBox>
    </FormikProvider>
  )
}

export default ProjectSharedLinkAccessForm
