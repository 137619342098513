/* eslint-disable import/no-default-export */
import { IonImg } from '@ionic/react'
import styled from 'styled-components'
import { useProjectContext } from '../../contexts/project'
import { useProjectQuery } from '../../pages/ProjectPage/ProjectPage.hooks'
import { ThumbnailedAsset } from '../../pages/ProjectPage/ProjectPage.types'
import { AssetPopoverMenu } from '../AssetPopoverMenu'
import { ProjectDigitalTwinAssetDialog } from '../ProjectDigitalTwinAssetsDialog'
import { ProjectDigitalTwinAssetDialogHookProps } from '../ProjectDigitalTwinAssetsDialog/ProjectDigitalTwinAssetDialog.types'
import { MediaItemCard } from '../ui/Card'
import { useDialog } from '../ui/Dialog/Dialog.hooks'

import { TreedisAssetsCardProps } from './ProjectDigitalTwinAssets.types'
import placeholder from '../../assets/placeholder_captur3d.png'
import { withCallbackTracker } from '../../hocs/withTracking'

const StyledImg = styled(IonImg)`
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 185px;

  @media (max-width: 768px) {
    height: 100px;
  }
`

const TreedisAssetCard = ({ treedis }: TreedisAssetsCardProps) => {
  const { projectId } = useProjectContext()
  const { project } = useProjectQuery({
    id: projectId.toString(),
  })

  const [openDialog] = useDialog<ProjectDigitalTwinAssetDialogHookProps>(
    ProjectDigitalTwinAssetDialog,
    {
      digitalTwinAsset: treedis,
    },
  )
  const editMenu = project.permissions.includes('DeleteProjectAssets')
    ? (asset: ThumbnailedAsset) => <AssetPopoverMenu asset={asset} />
    : () => null
  return (
    <MediaItemCard editMenu={() => editMenu(treedis)} title={treedis.name}>
      <StyledImg
        onClick={withCallbackTracker(openDialog, 'FE - Asset View', {
          Asset_Type: 'Treedis',
          Asset_Name: treedis.name,
          Project_ID: projectId,
          Project_Name: project.title,
          Project_Address: project.address,
        })}
        src={treedis.previewUrl || placeholder}
        alt={treedis.name}
      />
    </MediaItemCard>
  )
}

export default TreedisAssetCard
